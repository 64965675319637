import { Button, Card, Col, message, Row, Table, Input, Modal, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from "res";
import { fetchAllContent, deleteContent } from 'redux/features/content';
import moment from 'moment';
import { fetchAllCategory } from 'redux/features/category';
import { filter } from 'lodash';

const LocalizedModal = () => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const hideModal = () => {
        setOpen(false);
    };
    return (
        <>
            <Modal
                title="Modal"
                open={open}
                onOk={hideModal}
                onCancel={hideModal}
                okText="确认"
                cancelText="取消"
            >
                <p>Bla bla ...</p>
                <p>Bla bla ...</p>
                <p>Bla bla ...</p>
            </Modal>
        </>
    );
};


export const CONTENTS = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState()
    const [modal, contextHolder] = Modal.useModal();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [filters, setFilters] = useState({        
        page: 1,
        limit: 10,
        sort: "created_at",
        category_id: "",
    });

    const tableColumns = [{
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Thumbnail',
        dataIndex: 'thumbnail',
        key: 'thumbnail',
        render: (_, record) => (
            <div style={{margin:"auto"}}>
                <img src={record.thumbnail} style={{ maxWidth: "15%" }}></img>
            </div>
        ),
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (_, record) => {
            return categories.find(item => item.value === record.category_id)?.label
        }
    },
    {
        title: 'Tanggal',
        dataIndex: 'dibuat',
        key: 'dibuat',
        render: (_, record) => (
            record.created_at ? moment(record.created_at).format("DD-MM-YYYY") : "-"
        ),
    },
    {
        title: 'Jam',
        dataIndex: 'dibuat',
        key: 'dibuat',
        render: (_, record) => (
            record.created_at ? moment(record.created_at).format("HH:MM:SS") : "-"
        ),
    },
    {
        title: () => <div className="text-center">Detail</div>,
        key: 'detail',
        render: (_, record) => (
            <div className="text-center">
                <Button type="primary" style={{ textAlign: "center" }} onClick={() => {
                    history.push({
                        pathname: `${strings.navigation.path.detail_content}`,
                        state: record,
                    })
                }}>Detail</Button>
            </div>
        ),
    },
    {
        title: () => <div className="text-center">Action</div>,
        key: 'status',
        render: (_, record) => (
            <div className="text-center">
                <Button type="danger" style={{ textAlign: "center", color: "white" }} onClick={() => {
                    confirm(record.id)
                }}>Delete</Button>
            </div>
        ),
    },
    ]

    const getData = async (params) => {
        try {
            const response = await dispatch(fetchAllContent(params)).unwrap()
            setData(response.data)
            setLoading(false)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getCategories = async () => {
        try {
            const response = await dispatch(fetchAllCategory()).unwrap()
            setCategories(response.data.map(category => {
              return {
                value: category.id,
                label: category.name
              }
            }))
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const confirm = (id) => {
        modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure wanna delete this?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                await dispatch(deleteContent(id))
                getData(filters)
            },
            onCancel: () => {

            }
        });
    };
    
    const handleCategorySelect = (val) => {
        setFilters({...filters, category_id: val})
    }

    useEffect(() => {
        getData(filters)
        getCategories();
    }, [])

    useMemo(() => {
        getData(filters)
    }, [filters])



    return (
        <>
            <LocalizedModal></LocalizedModal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Contents</h2>
                    <p>All Contents</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Select 
                            placeholder='Filter by category' 
                            onSelect={handleCategorySelect} 
                            options={categories} 
                            className='mb-4'
                            allowClear
                            onClear={handleCategorySelect}
                            />
                        <Table
                            className="no-border-last"
                            style={{textAlign:'center','margin':'auto'}}
                            columns={tableColumns}
                            dataSource={data}
                            rowKey='id'
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: `${strings.navigation.path.detail_content}`
                        })
                    }} block>
                        Add New Content
                    </Button>
                </Col>
            </Row>
            {contextHolder}
        </>
    )
}


export default withRouter(CONTENTS);
